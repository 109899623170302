import { createBrowserRouter } from 'react-router-dom'
import { lazy, Suspense } from 'react'

const Lock = lazy(() => import('./app/Auth/Lock'))
const NewAccount = lazy(() => import('./app/Auth/NewAccount'))
const AppContainer = lazy(() => import('./app/ui/AppContainer'))
const ConditionalRoute = lazy(() => import('./app/Auth/ConditionalRoute'))
const ResetPassword = lazy(() => import('./app/Auth/ResetPassword'))
const ForgotPassword = lazy(() => import('./app/Auth/ForgotPassword'))
const SignUp = lazy(() => import('./app/Auth/SignUp'))
const Login = lazy(() => import('./app/Auth/Login2'))
const Home = lazy(() => import('./app/ui/Home/Home'))
const CollectionView = lazy(() => import('./app/ui/Collections/views/CollectionView'))
const DropFolderView = lazy(() => import('./app/ui/DropFolders/views/DropFolderView'))
const DropFolders = lazy(() => import('./app/ui/DropFolders'))
const Asset = lazy(() => import('./app/ui/Asset'))
const Project = lazy(() => import('./app/ui/Project'))
const Statistics = lazy(() => import('./app/ui/Settings/Statistics'))
const Users = lazy(() => import('./app/ui/Settings/Users'))
const Webhooks = lazy(() => import('./app/ui/Settings/Webhooks'))
const PageNotFound = lazy(() => import('./app/ui/PageNotFound'))
const Profile = lazy(() => import('./app/ui/Profile'))
const Loader = lazy(() => import('./app/ui/Loader'))
const RootLoader = lazy(() => import('./loader'))
const Logout = lazy(() => import('./app/Auth/Logout'))
const SearchResults = lazy(() => import('./app/ui/Search/SearchResults'))
const ComponentsPreview = lazy(() => import('./app/ui/Components/ComponentsPreview'))

const searchPaths = ['/trashbin', '/favorites', '/unassigned', '/search', '/projects', '/collections', '/processing']

// Only static routes are allowed in the router

const searchRoutes = searchPaths.map((path) => ({
  path,
  element: (
    <Suspense fallback={null}>
      <SearchResults />
    </Suspense>),
}))

const router = createBrowserRouter([
  {
    element: (
      <Suspense fallback={null}>
        <RootLoader />
      </Suspense>),
    children: [
      {
        element: (
          <Suspense fallback={null}>
            <ConditionalRoute condition="appcontainer" />
          </Suspense>
        ),
        // Children will be rendered based on authorization in ConditionalRoute
        children: [
          {
            path: '/auth/logout',
            element: (
              <Suspense fallback={null}>
                <Logout />
              </Suspense>),
          },
          {
            element: (
              <Suspense fallback={null}>
                <AppContainer />
              </Suspense>
            ),
            children: [
              {
                path: '/components-preview',
                element: (
                  <Suspense fallback={null}>
                    <ComponentsPreview />
                  </Suspense>
                ),
              },
              {
                path: '/',
                element: (
                  <Suspense fallback={null}>
                    <Home />
                  </Suspense>),
              },
              {
                path: 'drop_folders',
                element: (
                  <Suspense fallback={null}>
                    <DropFolders />
                  </Suspense>),
              },
              {
                path: 'drop_folders/:id',
                element: (
                  <Suspense fallback={null}>
                    <DropFolderView />
                  </Suspense>),
              },
              {
                path: 'drop_folders/:id/:tab',
                element: (
                  <Suspense fallback={null}>
                    <DropFolderView />
                  </Suspense>),
              },
              {
                path: 'collections/:id',
                element: (
                  <Suspense fallback={null}>
                    <CollectionView />
                  </Suspense>),
              },
              {
                path: 'assets/:id',
                element: (
                  <Suspense fallback={null}>
                    <Asset />
                  </Suspense>),
              },
              {
                path: 'projects/:id',
                element: (
                  <Suspense fallback={null}>
                    <Project />
                  </Suspense>),
              },
              {
                path: 'profile',
                element: (
                  <Suspense fallback={null}>
                    <Profile />
                  </Suspense>),
              },
              {
                path: 'settings',
                element: (
                  <Suspense fallback={null}>
                    <ConditionalRoute condition="settings" />
                  </Suspense>
                ),
                // Children will be rendered based on authorization in ConditionalRoute
                children: [
                  {
                    path: 'statistics',
                    element: (
                      <Suspense fallback={null}>
                        <Statistics />
                      </Suspense>),
                  },
                  {
                    path: 'users',
                    element: (
                      <Suspense fallback={null}>
                        <Users />
                      </Suspense>),
                  },
                  {
                    path: 'webhooks',
                    element: (
                      <Suspense fallback={null}>
                        <Webhooks />
                      </Suspense>),
                  },
                ],
              },
              {
                element: (
                  <Suspense fallback={null}>
                    <ConditionalRoute condition="loader" />
                  </Suspense>
                ),
                // Children will be rendered based on authorization in ConditionalRoute
                children: [
                  {
                    path: 'loader',
                    element: (
                      <Suspense fallback={null}>
                        <Loader />
                      </Suspense>),
                  },
                ],
              },

              ...searchRoutes,
              {
                path: '*',
                element: (
                  <Suspense fallback={null}>
                    <PageNotFound />
                  </Suspense>),
              },
            ],
          },
        ],
      },

      {
        path: 'auth',
        element: (
          <Suspense fallback={null}>
            <ConditionalRoute condition="lock" />
          </Suspense>
        ),
        // Children will be rendered based on authorization in ConditionalRoute
        children: [
          {
            element: (
              <Suspense fallback={null}>
                <Lock />
              </Suspense>
            ),
            children: [
              {
                path: 'newAccount',
                element: (
                  <Suspense fallback={null}>
                    <NewAccount />
                  </Suspense>),
              },
              {
                path: 'resetPassword',
                element: (
                  <Suspense fallback={null}>
                    <ResetPassword />
                  </Suspense>),
              },
              {
                path: 'forgotPassword',
                element: (
                  <Suspense fallback={null}>
                    <ForgotPassword />
                  </Suspense>),
              },
              {
                element: (
                  <Suspense fallback={null}>
                    <ConditionalRoute condition="signup" />
                  </Suspense>
                ),
                // Children will be rendered based on authorization in ConditionalRoute
                children: [
                  {
                    path: 'signup',
                    element: (
                      <Suspense fallback={null}>
                        <SignUp />
                      </Suspense>),
                  },
                ],
              },
              {
                path: '*',
                element: (
                  <Suspense fallback={null}>
                    <Login />
                  </Suspense>),
              },
            ],
          },
        ],
      },
    ],
  },
])

export default router
