import Types from './actions/types'
import defaultState from './defaultState'

const reducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case Types.SET_CUSTOM_FIELDS_OPEN: {
      return {
        ...state,
        customFieldsOpen: action.payload,
      }
    }

    case Types.SET_PUBLISHING_CHANNELS_OPEN: {
      return {
        ...state,
        publishingChannelsOpen: action.payload,
      }
    }

    case Types.SET_SETTINGS: {
      const { settings, license } = action.payload
      return {
        ...state,
        settings,
        license,
      }
    }

    case Types.SET_LOGIN_SETTINGS: {
      const { settings } = action.payload
      return {
        ...state,
        loginSettings: settings,
      }
    }

    case Types.SET_UPPY: {
      return {
        ...state,
        uppy: action.payload,
      }
    }

    case Types.SET_COMPLETED_UPLOAD: {
      return {
        ...state,
        completedUploads: [...state.completedUploads, action.payload],
      }
    }

    case Types.CLEAR_COMPLETED_UPLOADS: {
      return {
        ...state,
        completedUploads: [],
      }
    }

    case Types.REMOVE_PROFILE: {
      return {
        ...state,
        profile: null,
      }
    }

    case Types.SET_PROFILE: {
      return {
        ...state,
        profile: action.payload,
      }
    }

    case Types.SET_FAVORITES: {
      return {
        ...state,
        favorites: action.payload,
      }
    }

    case Types.SET_PERMISSIONS: {
      return {
        ...state,
        permissions: action.payload,
      }
    }

    case Types.SET_FIELD_GROUPS: {
      return {
        ...state,
        fieldGroups: action.payload,
      }
    }

    case Types.SET_SORT: {
      return {
        ...state,
        sort: action.payload,
      }
    }

    case Types.SET_SEARCH_OPTIONS: {
      return {
        ...state,
        searchOptions: action.payload,
      }
    }

    case Types.TOGGLE_VIEW: {
      return {
        ...state,
        toggleView: action.payload,
      }
    }

    case Types.GET_NOTIFICATIONS: {
      return {
        ...state,
        notifications: action.payload,
      }
    }

    case Types.SET_SELECTED_SEARCH: {
      const { selectedSearch } = action.payload

      return {
        ...state,
        selectedSearch,
      }
    }

    case Types.SET_DATE_TO_HUMAN: {
      return {
        ...state,
        dateToHuman: action.payload,
      }
    }

    case Types.SET_ALERT_DIALOG: {
      if (!action.payload) return { ...state, alertDialog: null }
      const { alertDialog, suppressAlert } = state
      if (suppressAlert) return { ...state }
      return {
        ...state,
        alertDialog: alertDialog ? [...alertDialog, action.payload] : [action.payload],
      }
    }

    case Types.SET_SUPPRESS_ALERT: {
      return {
        ...state,
        suppressAlert: action.payload ? action.payload : false,
      }
    }

    case Types.TOGGLE_UPLOAD_MODAL: {
      return {
        ...state,
        uploadModalOpen: action.payload,
      }
    }

    case Types.TOGGLE_SIDE_DRAWER: {
      const { sideDrawerOpen } = state
      return {
        ...state,
        sideDrawerOpen: !sideDrawerOpen,
      }
    }

    case Types.SIDE_DRAWER_UPDATE_SETTINGS: {
      const { sideDrawerSettings } = state
      const { settingToUpdate } = action.payload

      return {
        ...state,
        sideDrawerSettings: {
          ...sideDrawerSettings,
          [settingToUpdate]: !sideDrawerSettings[settingToUpdate],
        },
      }
    }

    case Types.SET_SELECTED_PRESET: {
      return {
        ...state,
        selectedPreset: action.payload,
      }
    }

    case Types.SET_DATA_FROM_SEARCH: {
      return {
        ...state,
        dataFromSearch: action.payload,
      }
    }

    case Types.SET_COPIED_METADATA: {
      return {
        ...state,
        copiedMetadata: action.payload,
      }
    }

    case Types.SET_SHOW_DELETED: {
      return {
        ...state,
        showDeleted: action.payload,
      }
    }

    case Types.SET_TITLE_HEIGHT: {
      return {
        ...state,
        titleHeight: action.payload,
      }
    }

    case Types.SET_NAV_BAR_HEIGHT: {
      return {
        ...state,
        navBarHeight: action.payload,
      }
    }

    case Types.SET_OVERRIDE_SEARCH_LOADING: {
      return {
        ...state,
        overrideSearchLoading: action.payload,
      }
    }

    default:
      return state
  }
}

export default reducer
