import defaultDataFields from '../ui/Search/defaultDataFields'

const defaultState = {
  customFieldsOpen: false,
  publishingChannelsOpen: false,
  settings: null,
  loginSettings: null,
  license: null,
  profile: null,
  permissions: null,
  selectedPreset: null,
  selectedSearch: null,
  sort: 'Best match',
  searchOptions: {
    autoSuggest: true,
    searchOperators: true,
    voiceSearch: false,
    fuzziness: 0,
    operator: 'and',
    dataFields: defaultDataFields,
  },
  toggleView: 0,
  notifications: null,
  fieldGroups: [],
  dateToHuman: true,
  uppy: null,
  completedUploads: [],
  alertDialog: null,
  suppressAlert: false,
  uploadModalOpen: false,
  sideDrawerOpen: false,
  sideDrawerSettings: {
    showPath: true,
    fullTitle: true,
  },
  showDeleted: false,
  titleHeight: 0,
  navBarHeight: 0,
  overrideSearchLoading: false,
}

export default defaultState
